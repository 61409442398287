<template>
  <page-container :title="dataList.employee_number + '#' + dataList.full_name + ' - ' + t('详情页')">
    <a-card :bordered="true">
      <a-descriptions size="small" :column="4">
        <template #title>
          <left-outlined
            style="font-size: 16px; color: inherit; font-style: normal; font-weight: bold;margin-right: 15px;color: #9a9a9a"
            :title="t('上一个')"
            @click="jump_new_adviser(true)" />
          {{ t('顾问信息') }} # {{ dataList.employee_number }}
          <right-outlined
            style="font-size: 16px; color: inherit; font-style: normal; font-weight: bold;margin-left: 15px;color: #9a9a9a"
            :title="t('下一个')"
            @click="jump_new_adviser(false)" />
          <span v-if="dataList.wx_bind" style="margin-left: 20px;color: limegreen">【{{ t('已绑定微信小程序') }}】</span>
          <span v-if="!dataList.wx_bind" style="margin-left: 20px;color: #f5222d">【{{ t('未绑定微信小程序') }}】</span>
          <a-avatar v-if="dataList.icon_url" style="margin-left: 20px" :size="40" :src="dataList.icon_url" />
          <span v-if="dataList.nick_name" style="color: blueviolet;margin-left: 5px">{{ dataList.nick_name }} </span>
        </template>

        <template #extra>
          <a-button
            size="small"
            type="primary"
            @click="btn_update_adviser.cb(dataList, t)"
            style="margin-right: 3px"
          >
            {{ t('修改') }}
          </a-button>
          <a-button
            size="small"
            type="primary"
            @click="btn_create_todoevent.cb(dataList, t)"
            style="margin-right: 3px"
          >
            {{ t('新建待办事项') }}
          </a-button>
          <a-button
            size="small"
            type="primary"
            v-if="!dataList.adviser_user_id"
            @click="btn_create_adviser.cb(dataList, t)"
            style="margin-right: 3px"
          >
            {{ t('创建账号') }}
          </a-button>
          <a-button
            size="small"
            type="danger"
            v-if="dataList.adviser_user_id"
            @click="btn_dle_adviser_suer.cb(dataList, t)"
            style="margin-right: 3px"
          >
            {{ t('删除账号') }}
          </a-button>
          <a-button
            size="small"
            type="success"
            v-if="!dataList.is_account_enable && dataList.status == 2"
            @click="btn_recover_adviser_suer.cb(dataList, t)"
            style="margin-right: 3px"
          >
            {{ t('恢复账号') }}
          </a-button>
          <a-button
            size="small"
            type="danger"
            v-if="dataList.adviser_user_id"
            @click="btn_reset_adviser_suer.cb(dataList, t)"
            style="margin-right: 3px"
          >
            {{ t('重置密码') }}
          </a-button>
          <a-button
            size="small"
            type="primary"
            v-if="dataList.adviser_user_id"
            @click="btn_send_adviser_user.cb(dataList, t)"
            style="margin-right: 3px"
          >
            {{ t('发送邮件') }}
          </a-button>
          <a-button
            size="small"
            type="danger"
            :disabled="!dataList.end_date1"
            @click="btn_dle_adviser.cb(dataList, t)"
            style="margin-right: 3px"
          >
            {{ t('删除结束时间') }}
          </a-button>
          <a-button
            size="small"
            type="danger"
            v-if="dataList.wx_bind"
            @click="btn_unbound_weixin.cb(dataList, t)"
            style="margin-right: 3px"
          >
            {{ t('小程序解绑') }}
          </a-button>
        </template>
        <a-descriptions-item :label="t('称谓')">{{ dataList.title }}</a-descriptions-item>
        <a-descriptions-item :label="t('手机')">{{ dataList.mobile }}</a-descriptions-item>
        <a-descriptions-item :label="t('微信账号')">{{ dataList.wechat }}</a-descriptions-item>
        <a-descriptions-item :label="t('个人类别')">{{ dataList.person_type__name }}</a-descriptions-item>

        <a-descriptions-item :label="t('姓名')">{{ dataList.full_name }}</a-descriptions-item>
        <a-descriptions-item :label="t('电话')">{{ dataList.phone }}</a-descriptions-item>
        <a-descriptions-item :label="t('WhatsApp')">{{ dataList.whats_app }}</a-descriptions-item>
        <a-descriptions-item :label="t('个人状态')">
          {{
            dataList.status === 1
              ? t('-无-')
              : dataList.status === 2
                ? t('工作中')
                : dataList.status === 3
                  ? t('结束，不需要')
                  : dataList.status === 4
                    ? t('申请签证中')
                    : dataList.status === 5
                      ? t('尚未开始')
                      : dataList.status === 6
                        ? t('潜在雇员')
                        : dataList.status === 7
                          ? t('离职')
                          : dataList.status === 8
                            ? t('待定')
                            : ''
          }}
        </a-descriptions-item>

        <a-descriptions-item :label="t('国籍')">{{ dataList.nationality__name }}</a-descriptions-item>
        <a-descriptions-item :label="t('个人邮箱')"><a :href="'mailto:' + dataList.personal_email"
                                                       target="_top">{{ dataList.personal_email }}</a>
        </a-descriptions-item>
        <a-descriptions-item :label="t('QQ账号')">{{ dataList.qq }}</a-descriptions-item>
        <a-descriptions-item :label="t('保险信息')">
          {{
            dataList.insurance_type === 0
              ? t('需要')
              : dataList.insurance_type === 1
                ? t('不需要–顾问在本地')
                : dataList.insurance_type === 2
                  ? t('不需要–顾问自己有保单')
                  : dataList.insurance_type === 3
                    ? t('不需要-顾问公司支付')
                    : ''
          }}
        </a-descriptions-item>

        <a-descriptions-item :label="t('出生日期')">{{ getDate(dataList.birth_date) }}</a-descriptions-item>
        <a-descriptions-item :label="t('工作邮箱')"><a :href="'mailto:' + dataList.work_email"
                                                       target="_top">{{ dataList.work_email }}</a></a-descriptions-item>
        <a-descriptions-item label="Skype ID">{{ dataList.skypelid }}</a-descriptions-item>
        <a-descriptions-item :label="t('付款币种')">
          {{ dataList.pay_currency__currency }}
        </a-descriptions-item>
        <a-descriptions-item :label="t('其他名字')">
          {{ dataList.other_name }}
        </a-descriptions-item>
        <a-descriptions-item :label="t('顾问结束日期')">
          {{ dataList.end_date1 }}
        </a-descriptions-item>
        <a-descriptions-item :label="t('顾问账号名')">
          {{ dataList.adviser_username }}
        </a-descriptions-item>
        <a-descriptions-item :label="t('顾问账号状态')">
          <a-tag v-if="dataList.is_account_enable" color="green">{{ t('正常') }}</a-tag>
          <a-tag v-if="!dataList.is_account_enable" color="red">{{ t('禁用') }}</a-tag>
        </a-descriptions-item>
        <a-descriptions-item :label="t('cc银行账户')">{{ dataList.cc_bank_account__account_flag }}</a-descriptions-item>
        <a-descriptions-item :label="t('标签')">
          <template v-if="dataList?.tags?.length > 0">
            <a-tag v-for="(item, i) in dataList?.tags" :key="i" color="green">{{ item.name }}</a-tag>
          </template>
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions :column="3" size="small">
        <a-descriptions-item :label="t('家庭地址')">
          {{ dataList.home_address__display_name }}
        </a-descriptions-item>
        <a-descriptions-item :label="t('工作地址')">
          {{ dataList.work_address__country__name }}
        </a-descriptions-item>
        <a-descriptions-item :label="t('备注')"><p style="white-space: pre-line">{{ dataList.person_note }}</p>
        </a-descriptions-item>
      </a-descriptions>
      <a-divider style="margin-bottom: 10px;margin-top: 15px" />
      <template v-for="(item, index) in current_contract_list" :key="index">
        <a-descriptions :column="3" size="small">
          <a-descriptions-item :label="t('当前合同负责人')">{{ is_current ? item.creater : '' }}</a-descriptions-item>
          <a-descriptions-item :label="t('CC公司')">{{ item.cc_company__name }}</a-descriptions-item>
          <a-descriptions-item label="">
            <div style="width: 100%">
              <a-button
                size="small"
                style="float: right"
                type="primary"
                @click="ShowContract(item)"
              >
                {{ item.is_show ? t('收起') : t('展开当前合同') }}
                <CaretDownOutlined v-if="!item.is_show" />
                <CaretUpOutlined v-if="item.is_show" />
              </a-button>
            </div>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions v-if="dataList.status === 2 && item.is_show"
                        :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }" size="small">
          <a-descriptions-item :label="t('职务')">{{ item.consultant }}</a-descriptions-item>
          <a-descriptions-item :label="t('项目名称')">{{ item.project_name }}</a-descriptions-item>
          <a-descriptions-item :label="t('顾问公司')">{{ item.recuitment_company__company_name }}</a-descriptions-item>
          <a-descriptions-item :label="t('顾问公司联系人')">{{ item.contact_person__name }}</a-descriptions-item>
        </a-descriptions>
        <a-descriptions v-if="dataList.status === 2 && item.is_show" :column="2" size="small">
          <a-descriptions-item :label="t('工作地点')">{{ item.work_location__name }}</a-descriptions-item>
          <a-descriptions-item :label="t('合同起始结束日期')">{{
              getDate(item.start_date)
            }}-{{ getDate(item.end_date) }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions v-if="dataList.status === 2 && item.is_show"
                        :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }" size="small">
          <a-descriptions-item :label="t('基本单位薪资')">{{ item.basic_rate }}</a-descriptions-item>
          <a-descriptions-item :label="t('薪资种类')">
            {{
              item.rate_type == 0 ? t('日薪') :
                item.rate_type == 1 ? t('月薪') :
                  item.rate_type == 2 ? t('年薪') :
                    item.rate_type == 3 ? t('时薪') :
                      item.rate_type == 4 ? t('周薪') : ''
            }}
          </a-descriptions-item>
          <a-descriptions-item :label="t('薪资币种')">{{ item.currency__currency }}</a-descriptions-item>
          <a-descriptions-item label=""></a-descriptions-item>
        </a-descriptions>

        <a-descriptions v-if="dataList.status === 2 && item.is_show"
                        :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }" size="small">
          <a-descriptions-item :label="t('管理费支付方式')">{{
              item.management_fee_payment_flag == 0 ? t('顾问支付') :
                item.management_fee_payment_flag == 1 ? t('顾问公司支付') : ''
            }}
          </a-descriptions-item>
          <a-descriptions-item :label="t('cc管理费')">{{ item.cc_management_fee }}</a-descriptions-item>
          <a-descriptions-item :label="t('cc管理费率（%）')">{{ item.cc_management_fee_rate }}</a-descriptions-item>
          <a-descriptions-item label=""></a-descriptions-item>
        </a-descriptions>
        <a-descriptions v-if="dataList.status === 2 && item.is_show"
                        :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }" size="small">

          <a-descriptions-item :label="t('保险费支付方式')">{{
              item.insurance_payment_flag == -1 ? t('无') :
                item.insurance_payment_flag == 0 ? t('顾问支付') :
                  item.insurance_payment_flag == 1 ? t('顾问公司支付') : ''
            }}
          </a-descriptions-item>
          <a-descriptions-item :label="t('保险类型')">{{
              item.insurance_type == 0 ? t('需要') :
                item.insurance_type == 1 ? t('不需要–顾问在本地') :
                  item.insurance_type == 2 ? t('不需要–顾问自己有保单') :
                    item.insurance_type == 3 ? t('不需要–顾问公司保险') : ''
            }}
          </a-descriptions-item>
          <a-descriptions-item :label="t('顾问保险费')">{{ item.insurance_fee }}</a-descriptions-item>
          <a-descriptions-item :label="t('顾问公司保险费')">{{ item.c_insurance_fee }}</a-descriptions-item>
        </a-descriptions>
        <a-descriptions v-if="dataList.status === 2 && item.is_show"
                        :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }" size="small">
          <a-descriptions-item :label="t('银行转账费支付方式')">{{
              item.is_bank_charge_payment ? t('顾问支付') : t('顾问公司支付')
            }}
          </a-descriptions-item>
          <a-descriptions-item :label="t('银行转账费')">{{ item.bank_charge }}</a-descriptions-item>
          <a-descriptions-item :label="t('是否运用本地解决方案')">{{
              item.solution_type == 0 ? t('是') :
                item.solution_type == 1 ? t('否') : ''
            }}
          </a-descriptions-item>
          <a-descriptions-item label=""></a-descriptions-item>
        </a-descriptions>
        <a-descriptions v-if="dataList.status === 2 && item.is_show"
                        :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }" size="small">

          <a-descriptions-item :label="t('本地发薪工资')">{{ item.local_management_fee_fixed }}</a-descriptions-item>
          <a-descriptions-item :label="t('本地管理费')">{{ item.local_management_fee }}</a-descriptions-item>
          <a-descriptions-item :label="t('本地管理费率（%）')">{{ item.local_management_fee_rate }}</a-descriptions-item>
          <a-descriptions-item :label="t('本地扣减费')">{{ item.local_deduct_fee }}</a-descriptions-item>
        </a-descriptions>
        <a-descriptions v-if="dataList.status === 2 && item.is_show" :column="1" size="small">
          <a-descriptions-item :label="t('备注')"><p style="white-space: pre-line">{{ item.remark }}</p>
          </a-descriptions-item>
        </a-descriptions>

        <a-divider v-if="dataList.status === 2" style="margin-bottom: 10px;margin-top: 1px" />
      </template>
      <a-tabs v-if="is_refresh" v-model:activeKey="activeKey" type="card" @change="handleChangeTab">
        <a-tab-pane v-for="item in tableList" :key="item.title">
          <template #tab>
            {{ (t && t(`${item.title}`)) || item.title }}
            <a-tag v-if="todoevent_num > 0 && item.title === '待办事项'" color="red">{{ todoevent_num }}</a-tag>
            <a-tag v-if="extra_field_num > 0 && item.title === '增扣提醒'" color="red">{{ extra_field_num }}</a-tag>
            <!--            <a-tag v-if="bz_num > 0 && item.title === '备注'" color="red">{{ bz_num }}</a-tag>-->
          </template>
          <page-model-2
            :modalType="item.modalType"
            :search="item.search"
            :title="item.title"
            :is_month="item.title === '工时'"
            :column_flag="item.column_flag"
            :height2="item.title === '工时' ? 150 : 0"
            :is_pagination="item.title === '工时' ? false : true"
            :mode="item.title === '工时' ? 'radio' : null"
            @ok="Refresh"
            @onLoad="OnLoad"
            @onCustomEvent="handleCustomEvent"
          />
          <div v-if="item.title === '工时'" class="ant-pro-table">
            <a-card ref="elRef" :body-style="{ padding: 0 }" class="mytable">
              <div ref="elToolbar" class="ant-pro-table-list-toolbar">
                <div class="ant-pro-table-list-toolbar-container">
                  <div class="ant-pro-table-list-toolbar-left">
                    <div class="ant-pro-table-list-toolbar-title">
                      {{ t('顾问工时（锁定后：月工时、月使用年假将不可调整）') }}
                      <a-tag color="orange" style="margin-left: 20px">{{ t('年假剩余') }} {{ available_annual_leave }}
                        {{ t('天') }}
                      </a-tag>
                    </div>
                  </div>
                  <div class="ant-pro-table-list-toolbar-right">
                    <a-space align="center">
                      <div class="ant-pro-table-list-toolbar-setting-item">
                        <a-tooltip title="导出Excel">
                          <export-excel-modal
                            :column-list="dynamicColumnItems"
                            :columns="baseColumns"
                            :datasource="dataSource"
                            :filename="filename"
                          />
                        </a-tooltip>
                      </div>
                    </a-space>
                  </div>
                </div>
              </div>
              <s-table
                :autoRowHeight="true"
                :bordered="true"
                :columns="baseColumns"
                :customCell="customCell"
                :data-source="dataSource"
                :height="200"
                :ignoreCellKey="true"
                :indentSize="0"
                :loading="loading"
                :pagination="false"
                :row-height="30"
                :rowKey="'index'"
                :showHeader="false"
                :size="'small'"
                :virtual="true"
                :wrapText="true"
                :xVirtual="false"
                sticky
                stripe
                summary-fixed
              >
                <template #headerCell="{ title }">
                  <div style="width: 100%;text-align:center;">
                    {{ title }}
                  </div>
                </template>
                <template #bodyCell="{ record, column, text, index }">
                  <template v-if="record?.style === 'week'">
                    <div style="width: 100%;text-align:center;">
                      <span style="font-weight: 500;color: #f0f2f5">{{ text }}</span>
                    </div>
                  </template>
                  <template v-else-if="column.dataIndex === 'name'">
                    <div style="width: 100%;text-align:center;">
                      <span style="font-weight: 600;">{{ text == 'GGK' ? 'Date' : text }}</span>
                    </div>
                  </template>
                  <template v-else-if="record.name === 'GGK'">
                    <div style="width: 100%;text-align:center;">
                      <span style="font-weight: 600;margin-right: 2px">{{ text?.value ? text?.value : text }}</span>
                      <a-tooltip v-if="text?.value">
                        <template #title><span style="font-weight: 600;color: #f6821a">{{ text?.name }}</span>
                        </template>
                        <StarFilled style="cursor: pointer;color: red" />
                      </a-tooltip>
                    </div>
                  </template>
                  <template
                    v-else-if="(record.name === 'Standard' || record.name === 'Overtime' || record.name === 'Annual Leave') && column.dataIndex !== 'name' && column.dataIndex !== 'total'">
                    <a-popover
                      :title="is_lock ? t('已锁定') : record.name === 'Standard' ? t('普通工时录入') : record.name === 'Overtime' ? t('加班工时录入') : record.name === 'Annual Leave' ? t('年假工时录入') : ''"
                      trigger="click">
                      <template v-if="!is_lock" #content>
                        <a-button :size="'middle'" style="margin-right: 2px" type="primary"
                                  @click="UpdateNum(record, column, 0)">0
                        </a-button>
                        <a-button :size="'middle'" style="margin-right: 2px" type="primary"
                                  @click="UpdateNum(record, column, 1)">1
                        </a-button>
                        <a-button :size="'middle'" style="margin-right: 2px" type="primary"
                                  @click="UpdateNumModal(record, column)">{{ t('录入') }}
                        </a-button>
                      </template>
                      <div style="width: 100%;height: 100%;text-align:center;cursor: pointer">
                        <span style="font-weight: 600">{{ (text || text == 0) ? text : `&nbsp;` }}</span>
                      </div>
                    </a-popover>
                  </template>
                  <template
                    v-else-if="record.name === 'Total Hours' || (column.dataIndex === 'total' && text !== 'total') ">
                    <div style="width: 100%;text-align:center;">
                      <span style="font-weight: 600;color: #2686BE">{{ text }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div style="width: 100%;text-align:center;">
                      {{ text }}
                    </div>
                  </template>
                </template>
              </s-table>
            </a-card>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </page-container>
</template>

<script lang="ts">
import { computed, defineComponent, onActivated, onDeactivated, reactive, ref, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  get_adviser_by_employee_number,
  get_adviser_month_work_hour_calendar,
  query_adviser_list,
  query_contract_list,
  query_extra_field_list,
  query_note_list,
  query_todoevent_list,
  update_month_work_hour_data,
} from '@/api/cerp-model';
import { useStore } from 'vuex';
import {
  btn_create_adviser,
  btn_create_todoevent,
  btn_dle_adviser,
  btn_dle_adviser_suer,
  btn_recover_adviser_suer,
  btn_reset_adviser_suer,
  btn_send_adviser_user,
  btn_unbound_weixin,
  btn_update_adviser,
} from '@/views/profile/basic/basic.ts';
import bus from '@/utils/bus';
import PageModel2 from '@/components/page-model2/index.vue';
import { notification } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';
import { CaretDownOutlined, CaretUpOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons-vue';
import { get_wechat_user_icon } from '@/api/sys-model';
import moment from 'moment';
import modalBox from '@/components/form-modal/modal-tools';

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();
    const event_obj = bus;
    const is_show_contract = ref(false);
    const is_current = ref(false);
    const is_refresh = ref(true);
    const filename = ref(t('顾问工时'));
    const month_work_hour_id: any = ref(null);
    const is_lock: any = ref(false);
    const available_annual_leave: any = ref(0);
    const dataSource: any = ref([]);
    const current_contract_list: any = ref([]);
    const loading: any = ref(false);
    const current_org = store.getters['user/current_org'];
    const current_company = computed(() => store.getters['user/current_company']);
    const activeKey = ref<string>('合同');
    const adviser_id = Number(route.params['id']);
    const adviser = reactive({
      id: Number(route.params['id']),
      name: decodeURIComponent(route.params['full_name'] as string),
      employee_number: decodeURIComponent(route.params['employee_number'] as string),
      contract_id: decodeURIComponent(route.params['contract_id'] as string),
    });
    const todoevent_num: any = ref(0);
    const extra_field_num: any = ref(0);
    const bz_num: any = ref(0);
    const contract_obj: any = ref({});
    const get_todoevent = () => {
      query_todoevent_list({
        adviser_id: adviser.id,
        current: 1,
        is_active: true,
        is_finished: false,
        org_id: current_org.id,
        pageSize: 20000,
      }).then((res: any) => {
        todoevent_num.value = res.total;
      });
    };
    get_todoevent();
    const get_extra_field_num = () => {
      query_extra_field_list({
        adviser_id: adviser.id,
        current: 1,
        is_active: true,
        is_finished: false,
        org_id: current_org.id,
        cc_company_id: current_company.value?.id,
        pageSize: 20000,
      }).then((res: any) => {
        extra_field_num.value = res.total;
      });
    };
    get_extra_field_num();
    const get_bz_num = () => {
      query_note_list({
        adviser_id: adviser.id,
        current: 1,
        is_active: true,
        org_id: current_org.id,
        pageSize: 20000,
      }).then((res: any) => {
        bz_num.value = res.total;
      });
    };
    get_bz_num();
    route.meta.title = Number(route.params['employee_number']) + '#' + route.params['full_name'];
    watchEffect(() => {
      if (route.path.split('/')[2] == 'basic') {
        route.meta.title =
          Number(route.params['employee_number']) + '#' + route.params['full_name'];
      }
    });
    const search = reactive({
      current: 1,
      pageSize: 20,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        adviser_id: adviser_id,
        cc_company_id: current_company.value?.id,
        adviser: adviser,
        org_id: current_org.id,
        is_active: true,
        num: 0,
      },
    });
    const dataList: any = reactive({
      full_name: '',
      icon_url: '',
      nick_name: '',
    });
    const getDate = (time: any) => {
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(0, 10);
      } else if (time) {
        time = time.substring(0, 10);
      }
      return time;
    };
    const Refresh = (record: any) => {
      if (!record?.id) {
        query_adviser_list({
          org_id: current_org.id,
          ids: adviser_id,
        }).then((res: any) => {
          if (res && res.data && res.data.length > 0) {
            Object.assign(dataList, res.data[0]);
            adviser.name = res.data[0].full_name;
            adviser.employee_number = res.data[0].employee_number;
            adviser.contract_id = res.data[0].contract_id;
            dataList.end_date1 = res.data[0].end_date;
            if (res.data[0].user_id) {
              get_wechat_user_icon({
                org_id: current_org.id,
                user_id: res.data[0].user_id,
              }).then((res: any) => {
                if (res.icon_url) {
                  dataList.icon_url = res.icon_url;
                  dataList.nick_name = res.nick_name;
                }
              });
            }
            query_contract_list({
              org_id: current_org.id,
              adviser_id: adviser_id,
              is_current: true,
              cc_company_id: current_company.value?.id,
            }).then((res1: any) => {
              current_contract_list.value.length = 0;
              if (res1 && res1.data && res1.data.length > 0) {
                dataList.adviser_id = dataList.id;
                Object.assign(dataList, res1.data[0]);
                dataList.id = dataList.adviser_id;
                res1.data.forEach((item: any) => {
                  current_contract_list.value.push({
                    ...item,
                    is_show: false,
                  });
                });
                is_current.value = true;
              } else {
                is_current.value = false;
                query_contract_list({
                  org_id: current_org.id,
                  adviser_id: adviser_id,
                  cc_company_id: current_company.value?.id,
                  sort_fields: '-create_time',
                }).then((res2: any) => {
                  if (res2 && res2.data && res2.data.length > 0) {
                    current_contract_list.value.push({
                      ...res2.data[0],
                      is_show: false,
                    });
                  }
                });
              }
            });
          } else {
            notification.error({
              message: t('提示'),
              description: t('未查到当前顾问信息'),
            });
          }
        });
      } else {
        dataSource.value.length = 0;
        loading.value = true;
        month_work_hour_id.value = record.id;
        is_lock.value = record.is_lock;
        get_adviser_month_work_hour_calendar({
          org_id: current_org?.id,
          month_work_hour_id: record.id,
        }).then((res: any) => {
          available_annual_leave.value = res.available_annual_leave;
          if (res && res.data_list && res.data_list.length > 0) {
            res.data_list.forEach((item1: any) => {
              item1.index = item1.month + item1.name;
            });
            Object.keys(res.data_list[1]).map((key: string) => {
              if (res.data_list[1][key] && key != 'name' && key != 'index' && key != 'month') {
                res.data_list[2][key] = { value: res.data_list[2][key], name: res.data_list[1][key] };
              }
            });
            dataSource.value.push({
              ...res.data_list[0],
              style: 'week',
            }, res.data_list[2], res.data_list[3], res.data_list[4], res.data_list[5], res.data_list[6]);
          }
          dataSource.value = [].concat(dataSource.value);
        }).finally(() => {
          loading.value = false;
        });
      }
    };
    Refresh(null);
    const OnLoad = (data: any) => {
      if (data && data?.length == 0) {
        dataSource.value.length = 0;
        dataSource.value = [].concat(dataSource.value);
        available_annual_leave.value = 0;
      }
    };
    const handleCustomEvent = (data: any) => {
      if (data && data?.type == 'get_extra_field_num') {
        get_extra_field_num();
      }
    };
    let get_todoevent_num = 0;
    watch(
      () => get_todoevent_num,
      () => {
        get_todoevent();
        get_extra_field_num();
      });
    // event_obj?.on('update_adviser', () => {
    //   Refresh(null)
    // });
    const event_obj_update_adviser = () => {
      Refresh(null);
      get_todoevent_num = get_todoevent_num + 1;
    };
    event_obj?.on('update_adviser', event_obj_update_adviser);

    onActivated(() => {
      event_obj?.off('update_adviser', event_obj_update_adviser);
      // 先解监听，再监听，防止重复
      event_obj?.on('update_adviser', event_obj_update_adviser);
      event_obj?.on('update_adviser', event_obj_update_adviser);
    });
    onDeactivated(() => {
      event_obj?.off('update_adviser', event_obj_update_adviser);
    });
    const tableList = reactive([
      {
        title: '合同',
        column_flag: 'contract_page2',
        search: { ...search, requestParams: { ...search.requestParams } },
        modalType: 'cerp-contract',
      },
      {
        title: '保险',
        column_flag: 'insurance',
        search: { ...search, requestParams: { ...search.requestParams } },
        modalType: 'cerp-insurance',
      },
      {
        title: '受益人',
        column_flag: 'beneficiary',
        search: { ...search, requestParams: { ...search.requestParams } },
        modalType: 'cerp-beneficiary',
      },
      {
        title: '银行账户信息',
        column_flag: 'bankaccount',
        search: { ...search, requestParams: { ...search.requestParams } },
        modalType: 'cerp-bankaccount',
      },
      {
        title: '工时',
        column_flag: 'month_work_hour_list',
        search: {
          ...search,
          requestParams: { ...search.requestParams, work_month: moment().format('YYYY-MM-DD') },
        },
        modalType: 'cerp-month_work_hour_list',
      },
      {
        title: '请款单',
        column_flag: 'invoice',
        search: { ...search, requestParams: { ...search.requestParams } },
        modalType: 'cerp-invoice',
      },
      {
        title: '工资单',
        column_flag: 'payslip',
        search: { ...search, requestParams: { ...search.requestParams } },
        modalType: 'cerp-payslip',
      },
      {
        title: '个人参考号',
        column_flag: 'personreferencenumber',
        search: { ...search, requestParams: { ...search.requestParams } },
        modalType: 'cerp-personreferencenumber',
      },
      {
        title: '紧急联系人',
        column_flag: 'nextofkin',
        search: { ...search, requestParams: { ...search.requestParams } },
        modalType: 'cerp-nextofkin',
      },
      {
        title: '备注',
        column_flag: 'note',
        search: { ...search, requestParams: { ...search.requestParams, cc_company_id: null } },
        modalType: 'cerp-note',
      },
      {
        title: '待办事项',
        column_flag: 'todoevent',
        search: { ...search, requestParams: { ...search.requestParams, is_finished: false, is_asc: false } },
        modalType: 'cerp-todoevent',
      },
      {
        title: '增扣提醒',
        column_flag: 'extra_field',
        search: { ...search, requestParams: { ...search.requestParams } },
        modalType: 'cerp-extra_field',
      },
    ]);
    bus.on('selectCompany', () => {
      is_refresh.value = false;
      Refresh(null);
      get_extra_field_num();
      dataSource.value.length = 0;
      setTimeout(() => {
        tableList.forEach((item: any) => {
          if (item.title == '备注') {
            item.search.requestParams.cc_company_id = null;
          } else {
            item.search.requestParams.cc_company_id = current_company.value?.id;
          }
        });
        is_refresh.value = true;
      }, 1000);
    });
    bus.on('onCustomEvent', (data) => {
      if (data && data?.type == 'get_extra_field_num') {
        get_extra_field_num();
      }
    });

    const jump_new_adviser = (is_true: any) => {
      get_adviser_by_employee_number({
        org_id: current_org.id,
        employee_number: adviser.employee_number,
        is_next: is_true,
      }).then((res: any) => {
        if (res && res.id) {
          router.push({
            path:
              '/profile/basic/' +
              res.id +
              '/' +
              res.full_name +
              '/' +
              res.employee_number +
              '/' +
              res.contract_id,
          });
        } else {
          notification.error({
            message: t('提示'),
            description: t('没有更多顾问'),
          });
        }
      });
    };
    const baseColumns = ref([
      {
        title: 'Name',
        dataIndex: 'name',
        minWidth: 105,
        autoHeight: true,
        fixed: 'left',
      },
      {
        title: '1',
        dataIndex: '1',
        width: 50,
        autoHeight: true,
      },
      {
        title: '2',
        dataIndex: '2',
        width: 50,
        autoHeight: true,
      },
      {
        title: '3',
        dataIndex: '3',
        width: 50,
        autoHeight: true,
      },
      {
        title: '4',
        dataIndex: '4',
        width: 50,
        autoHeight: true,
      },
      {
        title: '5',
        dataIndex: '5',
        width: 50,
        autoHeight: true,
      },
      {
        title: '6',
        dataIndex: '6',
        width: 50,
        autoHeight: true,
      },
      {
        title: '7',
        dataIndex: '7',
        width: 50,
        autoHeight: true,
      },
      {
        title: '8',
        dataIndex: '8',
        width: 50,
        autoHeight: true,
      },
      {
        title: '9',
        dataIndex: '9',
        width: 50,
        autoHeight: true,
      },
      {
        title: '10',
        dataIndex: '10',
        width: 50,
        autoHeight: true,
      },
      {
        title: '11',
        dataIndex: '11',
        width: 50,
        autoHeight: true,
      },
      {
        title: '12',
        dataIndex: '12',
        width: 50,
        autoHeight: true,
      },
      {
        title: '13',
        dataIndex: '13',
        width: 50,
        autoHeight: true,
      },
      {
        title: '14',
        dataIndex: '14',
        width: 50,
        autoHeight: true,
      },
      {
        title: '15',
        dataIndex: '15',
        width: 50,
        autoHeight: true,
      },
      {
        title: '16',
        dataIndex: '16',
        width: 50,
        autoHeight: true,
      },
      {
        title: '17',
        dataIndex: '17',
        width: 50,
        autoHeight: true,
      },
      {
        title: '18',
        dataIndex: '18',
        width: 50,
        autoHeight: true,
      },
      {
        title: '19',
        dataIndex: '19',
        width: 50,
        autoHeight: true,
      },
      {
        title: '20',
        dataIndex: '20',
        width: 50,
        autoHeight: true,
      },
      {
        title: '21',
        dataIndex: '21',
        width: 50,
        autoHeight: true,
      },
      {
        title: '22',
        dataIndex: '22',
        width: 50,
        autoHeight: true,
      },
      {
        title: '23',
        dataIndex: '23',
        width: 50,
        autoHeight: true,
      },
      {
        title: '24',
        dataIndex: '24',
        width: 50,
        autoHeight: true,
      },
      {
        title: '25',
        dataIndex: '25',
        width: 50,
        autoHeight: true,
      },
      {
        title: '26',
        dataIndex: '26',
        width: 50,
        autoHeight: true,
      },
      {
        title: '27',
        dataIndex: '27',
        width: 50,
        autoHeight: true,
      },
      {
        title: '28',
        dataIndex: '28',
        width: 50,
        autoHeight: true,
      },
      {
        title: '29',
        dataIndex: '29',
        width: 50,
        autoHeight: true,
      },
      {
        title: '30',
        dataIndex: '30',
        width: 50,
        autoHeight: true,
      },
      {
        title: '31',
        dataIndex: '31',
        width: 50,
        autoHeight: true,
      },
      {
        title: 'Total',
        dataIndex: 'total',
        width: 50,
        autoHeight: true,
        fixed: 'right',
      },
    ]);
    const dynamicColumnItems = computed(() => {
      let list: any = [];
      baseColumns.value.forEach((item: any) => {
        list.push(
          {
            checked: true,
            fixed: item.fixed ? item.fixed : false,
            key: item.dataIndex,
            label: item.title,
            width: item.width,
          },
        );
      });
      return list;
    });
    const customCell = (obj: any) => {
      if (obj.record?.style == 'week') {
        return { style: { background: '#40556C' } };
      }
      if (obj.record.name == 'GGK') {
        return { style: { background: '#FFB000' } };
      }
      if (obj.record.name == 'Total Hours') {
        return { style: { background: '#D9D9D9' } };
      }
    };
    const UpdateNum = (record: any, column: any, num: any) => {
      console.log('record', record[column.dataIndex]);
      console.log('column', column);
      if (record.name === 'Annual Leave' && available_annual_leave.value == 0) {
        if (!record[column.dataIndex]) {
          notification.error({
            message: t('温馨提示'),
            description:
              t('年假剩余天数为0，不可再增加！'),
          });
          return false;
        } else {
          if (record[column.dataIndex] <= num) {
            notification.error({
              message: t('温馨提示'),
              description:
                t('年假剩余天数为0，不可再增加！'),
            });
            return false;
          }
        }
      }
      let date = moment(record.month + '-' + (column.dataIndex > 9 ? column.dataIndex : ('0' + column.dataIndex))).format('YYYY-MM-DD');
      update_month_work_hour_data({
        org_id: current_org?.id,
        month_work_hour_id: month_work_hour_id.value,
        date: date,
        standard_value: record.name === 'Standard' ? num : null,
        overtime_value: record.name === 'Overtime' ? num : null,
        annual_leave: record.name === 'Annual Leave' ? num : null,
      }).then(() => {
        // Refresh({ id: month_work_hour_id.value });
        tableList[4].search.requestParams.num += 1;
      });
    };
    const UpdateNumModal = (record: any, column: any) => {
      let date = moment(record.month + '-' + (column.dataIndex > 9 ? column.dataIndex : ('0' + column.dataIndex))).format('YYYY-MM-DD');
      const editModal = reactive({
        visible: true,
        loading: false,
        title: t('工时录入'),
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'datepicker',
              name: 'date',
              label: '日期',
              label_i18n: '日期',
              disabled: true,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: true,
              valueFormat: 'YYYY-MM-DD',
              format: 'YYYY-MM-DD',
              placeholder: '请填写汇款收到日期',
            },
            {
              type: 'input',
              name: 'value',
              label: '工时',
              label_i18n: '工时',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder: '请填写工时',
              placeholder_i18n: '请填写工时',
            },
          ],
          rules: reactive({
            value: [{ required: true, message: t('请填写工时') }],
          }),
          model: reactive({
            org_id: current_org?.id,
            date: date,
            value: null,
          }),
          watch: {},
        },
        options: {},
        ok: (data: any) => {
          return new Promise(function (resolve) {
            editModal.loading = true;
            update_month_work_hour_data({
              org_id: current_org?.id,
              month_work_hour_id: month_work_hour_id.value,
              date: date,
              standard_value: record.name === 'Standard' ? data.value : null,
              overtime_value: record.name === 'Overtime' ? data.value : null,
              annual_leave: record.name === 'Annual Leave' ? data.value : null,
            })
              .then((res: any) => {
                // Refresh({ id: month_work_hour_id.value });
                tableList[4].search.requestParams.num += 1;
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    };
    const ShowContract = (record: any) => {
      if (record.is_show) {
        record.is_show = false;
      } else {
        record.is_show = true;
      }
    };
    const handleChangeTab = (activeKey:string)=> {
      const f:any = tableList.find((item:any)=>item.title === activeKey);
      if(f){
        if(!f.search.requestParams.action_num){
          f.search.requestParams.action_num = 1;
        }else{
          f.search.requestParams.action_num += 1;
        }
      }
    }
    return {
      dynamicColumnItems,
      baseColumns,
      tableList,
      dataList,
      activeKey,
      adviser,
      todoevent_num,
      extra_field_num,
      bz_num,
      btn_update_adviser,
      btn_dle_adviser,
      btn_create_todoevent,
      btn_create_adviser,
      btn_reset_adviser_suer,
      btn_recover_adviser_suer,
      btn_dle_adviser_suer,
      btn_send_adviser_user,
      btn_unbound_weixin,
      t,
      is_current,
      is_refresh,
      loading,
      is_lock,
      available_annual_leave,
      dataSource,
      filename,
      is_show_contract,
      current_contract_list,
      ShowContract,
      OnLoad,
      handleCustomEvent,
      UpdateNum,
      UpdateNumModal,
      customCell,
      Refresh,
      getDate,
      jump_new_adviser,
      handleChangeTab,
    };
  },
  components: {
    PageModel2,
    LeftOutlined,
    RightOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
  },
});
</script>

<style lang="less" scoped>
.title {
  margin-bottom: 16px;
  color: @text-color;
  font-weight: 500;
  font-size: 16px;
}
</style>
